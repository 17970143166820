<template>
  <v-container class="payment-completed" v-if="!loading">
    <v-container fluid class="mx-auto">
      <PaymentStepper :step="4" class="mx-0 mx-sm-auto mt-3 mt-sm-6" />
    </v-container>
    <v-row no-gutters class="mt-7">
      <v-col cols="12">
        <v-card class="h-100" color="transparent" elevation="0">
          <div
            class="d-flex align-center flex-row flex-wrap justify-center justify-sm-start"
          >
            <h1
              class="font-weight-bold primary--text"
              v-if="order && paymentFailed"
            >
              {{ $t("paymentCompleted.failed") }}
            </h1>
          </div>
          <v-card-text v-if="order && paymentFailed" class="px-0 mt-3">
            <div
              class="text-body-1 mb-6 text-center text-sm-left"
              v-html="$t('paymentCompleted.failedDesc')"
            />
          </v-card-text>

          <v-card-text
            v-if="order && !paymentFailed"
            class="text-center text-sm-left"
          >
            <h1
              class="font-weight-bold primary--text mb-6"
              v-html="$t('paymentCompleted.success', [order.orderId])"
            />
            <i18n
              path="paymentCompleted.pending"
              tag="div"
              class="text-body-1 font-weight-bold"
              v-if="paymentPending"
            >
              <template v-slot:link>
                <router-link
                  class="text-decoration-none"
                  :to="{
                    name: 'Order',
                    params: { orderId: order.orderId }
                  }"
                >
                  <span class="font-weight-bold">
                    {{ $t("paymentCompleted.ordersLink") }}</span
                  >
                </router-link>
              </template>
            </i18n>
            <!-- <div
              class="text-body-1 mt-2"
              v-html="$t('paymentCompleted.successDesc')"
            /> -->

            <v-container class="text-body-1 px-0 mt-6">
              <!-- PICKUP -->
              <div v-if="!order.isPayable && !order.homeDelivery && !easyDrive">
                <ul>
                  <li
                    v-html="$t('paymentCompleted.detail.date', [detailDate])"
                  />
                  <li v-html="detailTimeslot" />
                  <li
                    v-html="
                      $t('paymentCompleted.detail.pickup.where', [
                        shippingAddress
                      ])
                    "
                  />
                </ul>
                <!-- <div class="mt-6">
                  {{ $t("paymentCompleted.detail.pickup.last") }}
                </div> -->
                <div class="mt-3 primary--text font-weight-bold">
                  {{ $t("paymentCompleted.detail.noCart") }}
                </div>
              </div>

              <!-- DRIVE -->
              <div v-if="!order.isPayable && !order.homeDelivery && easyDrive">
                <ul>
                  <li
                    v-html="$t('paymentCompleted.detail.where', [detailDate])"
                  />
                  <li v-html="detailTimeslot" />
                  <li
                    v-html="
                      $t('paymentCompleted.detail.drive.where', [
                        order.shippingAddress.addressName
                      ])
                    "
                  />
                </ul>
                <!-- <div class="mt-6">
                  {{ $t("paymentCompleted.detail.drive.last") }}
                </div> -->
                <div class="mt-3 primary--text font-weight-bold">
                  {{ $t("paymentCompleted.detail.noCart") }}
                </div>
              </div>

              <!-- HOME -->
              <div v-if="!order.isPayable && order.homeDelivery">
                <ul>
                  <li
                    v-html="$t('paymentCompleted.detail.date', [detailDate])"
                  />
                  <li v-html="detailTimeslot" />
                  <li
                    v-html="
                      $t('paymentCompleted.detail.home.where', [
                        order.shippingAddress.address1,
                        order.shippingAddress.city
                      ])
                    "
                  />
                </ul>
                <!-- <div class="mt-6">
                  {{ $t("paymentCompleted.detail.home.last") }}
                </div> -->
              </div>
            </v-container>

            <v-divider class="my-5" />

            <!-- EDIT ORDER LINKS -->
            <v-container
              class="text-body-1 px-0"
              v-if="!order.isPayable && order.isEditable"
            >
              <ul>
                <li>
                  <template>
                    <i18n path="paymentCompleted.reminder.edit.1" tag="span">
                      <template v-slot:link>
                        <router-link
                          :to="'/profile/orders/' + order.orderId"
                          class="text-decoration-none"
                        >
                          <span class="font-weight-bold primary--text">
                            {{
                              $t("paymentCompleted.reminder.edit.edit")
                            }}</span
                          >
                        </router-link>
                      </template>
                    </i18n>
                    <span v-html="$t('paymentCompleted.reminder.edit.2')" />
                  </template>
                </li>
                <li>
                  <template>
                    <i18n path="paymentCompleted.reminder.edit.3" tag="span">
                      <template v-slot:link>
                        <router-link
                          to="/profile/orders"
                          class="text-decoration-none"
                        >
                          <span class="font-weight-bold primary--text">
                            {{
                              $t("paymentCompleted.reminder.edit.goToOrders")
                            }}</span
                          >
                        </router-link>
                      </template>
                    </i18n>
                  </template>
                </li>
              </ul>
            </v-container>

            <!-- DELETE ORDER LINKS -->
            <v-container class="text-body-1 px-0" v-if="!order.isEditable">
              <ul>
                <li>
                  <template>
                    <i18n path="paymentCompleted.reminder.delete.1" tag="span">
                      <template v-slot:link>
                        <router-link
                          :to="'/profile/orders/' + order.orderId"
                          class="text-decoration-none"
                        >
                          <span class="font-weight-bold primary--text">
                            {{
                              $t("paymentCompleted.reminder.delete.delete")
                            }}</span
                          >
                        </router-link>
                      </template>
                    </i18n>
                  </template>
                </li>
                <li>
                  <router-link
                    :to="'/profile/orders/' + order.orderId"
                    class="text-decoration-none"
                  >
                    <span class="font-weight-bold primary--text">
                      {{ $t("paymentCompleted.reminder.delete.2") }}</span
                    >
                  </router-link>
                </li>
              </ul>
            </v-container>
          </v-card-text>

          <v-card-text v-if="!order">
            <h1
              class="font-weight-bold default--text mb-4"
              v-html="$t('paymentCompleted.successNoOrder')"
            />
          </v-card-text>

          <v-card-actions class="">
            <v-row no-gutters justify="center" align="center">
              <v-col cols="12" sm="auto" class="px-0 px-sm-3 mb-4 mb-sm-0">
                <v-btn
                  x-large
                  depressed
                  class="rounded-xl secondary--text"
                  outlined
                  :block="$vuetify.breakpoint.xs"
                  @click="goHome"
                  >{{ $t("paymentCompleted.goToHome") }}</v-btn
                >
              </v-col>
              <v-col
                cols="12"
                sm="auto"
                v-if="paymentFailed && order.isPayable"
                class="mb-4 mb-sm-0"
              >
                <v-btn
                  v-if="!showPayment"
                  x-large
                  depressed
                  class="rounded-xl secondary--text"
                  color="primary"
                  :block="$vuetify.breakpoint.xs"
                  @click="toggleShowPayment"
                >
                  {{ $t("paymentCompleted.retry") }}
                </v-btn>
              </v-col>

              <v-col cols="12" sm="auto" class="px-0 px-sm-3">
                <v-btn
                  v-bind:to="'/profile/orders/' + order.orderId"
                  x-large
                  depressed
                  class="rounded-xl secondary--text"
                  color="primary"
                  :block="$vuetify.breakpoint.xs"
                >
                  {{ $t("paymentCompleted.goToDetail") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

          <PaymentTypeList
            v-if="showPayment"
            mode="order"
            :order-id="order.orderId"
            :options="paymentTypeListOptions"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.payment-completed {
  .v-stepper {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 80%;
    }
  }
  li {
    margin-bottom: 16px;
  }
  .payment-methods {
    background-color: white !important;
    border: 1px solid $border-color;
    :deep(.v-expansion-panel) {
      background-color: $white !important;
      border: 1px solid $border-color;
      padding: 0;
      border-radius: $border-radius-root;
      margin-bottom: 20px;
      &.v-expansion-panel--active {
        border-radius: $border-radius-root;
      }
      &:last-child {
        margin-bottom: 0px;
        border-top: none;
      }
      &::before {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }
      .v-expansion-panel-header {
        background-color: $white;
        border-radius: $border-radius-root !important;
        margin-bottom: 5px;
        label {
          display: flex;
          align-items: center;
          padding-left: 40px;
        }
        .payment-method-img {
          height: 50px !important;
        }
      }
      .v-expansion-panel-content__wrap {
        padding: 12px 24px !important;
        border-radius: $border-radius-root !important;

        .prebilled-amount {
          margin-bottom: 20px;
        }
        .caption {
          margin-top: 20px;
        }
      }

      .prebilledAmountInfo {
        margin-bottom: 20px;
        .text {
          color: var(--v-primary-lighten1);
        }
        .sub {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
<script>
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import PaymentStepper from "@/components/payment/PaymentStepper.vue";
import categoryMixins from "~/mixins/category";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "PaymentCompleted",
  mixins: [categoryMixins],
  components: {
    PaymentTypeList,
    PaymentStepper
  },
  data() {
    return {
      loading: true,
      order: null,
      showPayment: false,
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "secondary--text text--darken-2 text-body-1 mb-2",
        mainBackgroundColor: "transparent",
        confirm: { color: "primary", class: "secondary--text rounded-xl" },
        back: {
          outline: true,
          class: "secondary--text"
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isOrderSentToAnalytics: "app/isOrderSentToAnalytics"
    }),
    paymentFailed() {
      return this.order?.orderStatusId == 11;
    },
    paymentPending() {
      return this.order?.orderStatusId == 2;
    },
    detailDate() {
      return this.$dayjs(this.order.timeslot.date).format("DD MMMM");
    },
    detailTimeslot() {
      let from = this.$dayjs(this.order.timeslot.beginTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm");
      let to = this.$dayjs(this.order.timeslot.endTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm");
      if (!this.order.homeDelivery) {
        return `potrai ritirarlo dalle <b>${from}</b> alle
          <b>${to}</b>`;
      }
      return `ti verrà consegnato dalle <b>${from}</b> alle
          <b>${to}</b>`;
    },
    easyDrive() {
      return (
        this.order.warehouse.params != undefined &&
        this.order.warehouse.params.EASYDRIVE === "1"
      );
    },
    shippingAddress() {
      return `${this.order.shippingAddress.addressName} (${this.order.shippingAddress.address1}
            ${this.order.shippingAddress.addressNumber}, ${this.order.shippingAddress.city})`;
    }
  },
  methods: {
    ...mapActions({
      addOrderToAnalytics: "app/addOrderSentToAnalytics"
    }),
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    },
    async fetchOrder(orderId) {
      let _this = this;
      _this.order = await OrderService.getOrder(orderId);
      if (_this.order.orderStatusId == 3) {
        if (!this.isOrderSentToAnalytics(orderId)) {
          try {
            AnalyticsService.purchase(_this.order);
            this.addOrderToAnalytics(orderId);
          } catch (e) {
            console.log(e);
          }
        }
      }
      _this.loading = false;
    },
    goHome() {
      this.$router.push({ name: "Home" });
    }
  },
  mounted() {
    this.fetchOrder(this.$route.params.orderId);
    global.EventBus.$on("pay-response-ok", order => {
      this.showPayment = false;
      this.fetchOrder(order.orderId);
    });
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  }
};
</script>
